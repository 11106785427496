// current package (4.x) does not support material 15.x
// using these overrides to make the input sorta look good

.mat-mdc-form-field-type-ngx-mat-file-input.full-width .mat-input-element {
  width: 100%;
}


ngx-mat-file-input input {
  border: none;

  &::placeholder {
    display: none;
    font-size: 0;
  }
}
