.mdc-button__label {
  white-space: nowrap;
}

.mdc-button mat-icon {
  flex-shrink: 0;
}

.mat-mdc-fab.mat-mdc-fab,
.mat-mdc-mini-fab.mat-mdc-mini-fab {
  box-shadow: none;

  &:hover,
  &:focus, {
    box-shadow: none;
  }
}