@use '@angular/material' as mat;
@import "vars";

html,
body {
  height: 100%;
  margin: 0;
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
}

html {
  // your css to create a cover image
  padding: env(safe-area-inset); // <- this is the missing piece. Add it.
}

body {
  position: relative;
  background: $primary;
}

* {
  letter-spacing: 0 !important;
}

app-root {
  background: $white;
  display: block;
  height: 100%;
  overscroll-behavior: contain;
}

// Notched Apple devices https://stackoverflow.com/questions/60154236/is-viewport-fit-cover-no-longer-working-on-the-ios-safari
@supports (padding:max(0px)) {
  body {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

a {
  cursor: pointer;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }
}

textarea {
  min-height: 45px;
}

// Default bottom spacing for form fields
mat-form-field,
ng-select.ng-select,
mat-slide-toggle {
  &:not(.no-space-bottom) {
    margin-bottom: 12px;
  }
}

// MAT Extra Classes
.custom-mat-backdrop {
  background-color: rgba(black, .2);
}

.custom-mat-panel {
  max-width: none !important;

  .mat-mdc-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.custom-mat-filter-form {
  padding: 10px;
  width: 300px;
  position: relative;
}

// MAT Tooltip
.custom-mat-tooltip-multiline {
  white-space: pre-line;
}

.custom-mat-tooltip-multiline--right {
  white-space: pre-line;
  text-align: right;
}

// mat-menu-sub-title
.mat-sub-menu-sub-title {
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  font-size: 12px;
  background-color: whitesmoke;
  padding: 4px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0
}
