@use '@angular/material' as mat;
@import "mixins";
@import "vars";

.default-table-wrapper {
  position: relative;
  display: block;
  max-width: $default-table-wrapper-max-width;
  margin: 0 auto;

  &.is-loading {
    min-height: clamp(180px, 50vh, 300px);
  }

  &.default-table-wrapper--extra-wide {
    max-width: $default-table-wrapper-extra-wide-max;
  }
  &.default-table-wrapper--extra-wide-plus {
    max-width: $default-table-wrapper-extra-wide-max-plus;
  }
}

// Form
.table-wrapper__filter-form-add-button-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > button {
    margin-left: auto;
  }

  .filter-buttons {
    display: flex;
    align-items: center;
  }
}

.default-table-wrapper__min-max-fields {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &.is-active {
    border-bottom: 1px solid $gray-light;
    margin-bottom: 8px;
  }

  mat-slide-toggle {
    margin-right: auto;
  }

  .default-table-wrapper__min-max-fields--min {
    margin-right: 5px;
  }
}

.default-table-wrapper__duration-selection {
  .duration-label {
    display: block;
    text-align: center;
    font-size: 11px;
    line-height: 1;
    color: $gray-dark;
  }

  ngx-duration-picker {
    table {
      border-spacing: 0;
    }

    td {
      padding: 0;
      vertical-align: center;
      position: relative;
    }

    tr:nth-child(2) td:first-child:after {
      content: ':';
      font-weight: bold;
      position: absolute;
      left: 100%;
      margin-left: -1px;
    }

    a.btn {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: block;
      padding: 0;

      &:hover .bs-chevron {
        border-color: rgba($accent, .8);
      }
    }

    .bs-chevron {
      border-width: 4px 0 0 4px;
      top: 9px;
      left: 8px;
      border-color: rgba($accent, .5);
    }

    input {
      margin: 0;
      width: 22px;
      height: 22px;
      font-size: 10px;
      border-radius: 3px;
      border: 1px solid $gray;
      padding: 0;
      box-shadow: none;
    }
  }
}

.default-table-wrapper__filter-form {
  .filters {
    max-height: calc(100vh - 180px);
    z-index: 0;
    overflow-y: auto;

    .filters__checkboxes mat-checkbox {
      display: flex;
      width: 100%;
    }
  }

  mat-form-field {
    width: 100%;
  }

  .buttons {
    z-index: 1;
    position: sticky;
    bottom: 0;
    padding-top: 5px;
    background: rgba(white, 0.7);
    display: flex;
    align-items: center;

    .mat-mdc-unelevated-button {
      flex-grow: 1;

      &.untouched-form {
        opacity: .5;
      }
    }

    .mat-mdc-icon-button {
      flex-shrink: 0;
    }
  }

  .date-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;

    .clear-date-button {
      width: 32px;
      height: 32px;
      padding: 5px;
      color: $gray;
    }
  }
}

// Table & Pagination
.default-table-wrapper {
  .table-inner {
    position: relative;
    max-width: 100%;
    overflow-x: auto;
  }

  .paginator-wrapper {
    border-top: 1px solid $gray-light;

    &.fewer-records-than-minimum-page-size {
      max-height: 0;
      display: none;
    }
  }

  &--full-page {
    max-height: 100%;

    .table-inner {
      @include mat.elevation(2);
    }

    .paginator-wrapper {
      z-index: 1;
      position: sticky;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      right: 0;
    }

    .mat-mdc-paginator-container {
      background: rgba($gray-light, .5);
    }

    .mat-mdc-paginator {
      width: 100%;
      max-width: $default-table-wrapper-max-width - ($space * 2);
    }

    &.default-table-wrapper--extra-wide .mat-mdc-paginator {
      max-width: $default-table-wrapper-extra-wide-max - ($space * 2);
    }

    &.default-table-wrapper--extra-wide-plus .mat-mdc-paginator {
      max-width: $default-table-wrapper-extra-wide-max-plus - ($space * 2);
    }
  }
}

// No Records Found & Loading
.default-table__no-records-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: $space;

  p {
    @include mat.elevation(1);
    border-radius: 20px;
    background: rgba($notification, .5);
    color: white;
    display: inline-flex;
    line-height: 1.1;
    margin: 0;
  }

  @include mq($until: tp) {
    padding-top: 12px;
    p {
      padding: 6px 20px;
      font-size: 14px;
    }
  }

  @include mq($from: tp) {
    padding-top: 20px;
    p {
      padding: 10px 40px;
      font-size: 16px;
    }
  }
}

.default-table-wrapper {
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(white, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 102;
  }
}

.default-table-wrapper {
  @include elBreakpoint($until: ml) {
    .table-wrapper__filter-form-add-button-wrapper .above-table-button {
      min-width: 0;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @include elBreakpoint($until: d) {
    .table-wrapper__filter-form-add-button-wrapper {
      padding: 14px 10px 8px;

      .above-table-button {
        font-size: .8em;
        line-height: 2.8;
      }
    }

    .table-inner {
      background: white;
      overflow-x: auto;
    }

    .desktop-actions {
      display: none;
    }
  }
  @include elBreakpoint($from: d) {
    .table-wrapper__filter-form-add-button-wrapper {
      padding: $space;
    }

    &.default-table-wrapper--full-page {
      .table-wrapper {
        padding: 0 $space 0 $space;
      }
    }

    .menu-trigger {
      display: none;
    }

    .desktop-actions {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: -8px;

      .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 0;
      }

      .mat-mdc-icon-button.mat-mdc-button-base {
        width: 36px;
        height: 36px;
        font-size: 36px;
        line-height: 36px;
      }
    }
  }
}

// Select Cols
.default-table-wrapper .mat-column-_select {
  min-width: 60px;
  text-align: center;
  box-sizing: border-box;
  background: lighten($calm, 35);
}
