// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use "sass:map";
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

/* Styles to be applied to buttons */
$my-custom-button: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 500,
  $font-size: 1em,
  $line-height: 1,
  $letter-spacing: 'normal'
);

/* Styles to be applied to input-fields */
$my-custom-input: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: 'normal'
);

/* Merge custom configs into existing config */
$my-typography-config: map.merge(
    mat.define-typography-config(
      $button: $my-custom-button
    ),
    (
      /* 'input'-property will have to be assigned under '@mixin typography' further below */
      'input': $my-custom-input
    )
);

/* Apply custom config */
@include mat.all-component-typographies($my-typography-config);

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$epc-primary: mat.define-palette(mat.$blue-palette, 900);
$epc-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$epc-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$epc-theme: mat.define-light-theme(
    (
      color: (primary: $epc-primary, accent: $epc-accent, warn: $epc-warn),
      typography: $my-typography-config,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($epc-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import "~@ng-select/ng-select/themes/material.theme.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@include mtx.all-component-themes($epc-theme);

@import 'styles/exports/mixins';
@import 'styles/exports/vars';

@import 'styles/fonts/roboto';

@import 'material-icons/iconfont/filled.css';
@import 'material-icons/iconfont/outlined.css';

@import 'styles/vendor/overrides/angular-calendar';
@import 'styles/vendor/overrides/ng-select';
@import 'styles/vendor/overrides/ngx-mat-file-input';

@import 'styles/vendor/overrides/mat/paginator';
@import 'styles/vendor/overrides/mat/datepicker';
@import 'styles/vendor/overrides/mat/vertical-stepper';
@import 'styles/vendor/overrides/mat/bottom-sheet';
@import 'styles/vendor/overrides/mat/menu';
@import 'styles/vendor/overrides/mat/form-field';
@import 'styles/vendor/overrides/mat/button';
@import 'styles/vendor/overrides/mat/table';
@import 'styles/vendor/overrides/mat/option';

@import 'styles/helper-classes';
@import 'styles/global';

@import 'styles/dialogs/app-dialog';
@import 'styles/dialogs/full-screen-dialog';

@import 'styles/elements/default-table-wrapper';
@import 'styles/elements/default-detail-page';
@import 'styles/elements/data-summaries-and-durations';
@import 'styles/elements/chart';
@import 'styles/elements/sub-pages';
@import 'styles/elements/thumbnail';
@import 'styles/elements/company-type-wrapper';
@import 'styles/elements/app-button-is-pending.directive';
@import 'styles/elements/app-decimal-number-input.directive';
@import 'styles/elements/info-line';
@import 'styles/elements/work-order-accordion';
