@use '@angular/material' as mat;

// Screen sizes
$mq-media-type: screen;
$mq-breakpoints: (
  watch: 85px,
  mp: 320px,
  ml: 480px,
  tp: 680px,
  tl: 800px,
  d: 992px,
  dl: 1200px,
  dxl: 1600px,
  dxxl: 1900px
);

$space: 14px;
$customFormFieldsFontSize: 14px;
$customFormFieldsMinHeight: 44px;

$appDialogTpAndUpTopMargin: 5vh;

$default-table-wrapper-max-width: 1400px;
$default-table-wrapper-extra-wide-max: 2000px;
$default-table-wrapper-extra-wide-max-plus: 2500px;

$matInputFieldDisabledColor: rgba(0, 0, 0, .6);

$font-folder: '/assets/fonts/';

// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat.define-palette(mat.$blue-palette, 900);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

// MAT
$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);

// Experi-comps
$calm: #29b6f6;
$notification: #ff9800;
$positive: #4caf50;

// Grayscale
$white: white;
$gray: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette));
$gray-dark: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 400));
$gray-darker: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 600));
$gray-darkest: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 800));
$gray-light: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 300));
$gray-lighter: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 100));
$dark-primary-text: rgba(black, 0.87); // core/theming/_palette.scss
$dark-secondary-text: rgba(black, 0.54); // core/theming/_palette.scss

// Custom
$superAdmin: mat.get-color-from-palette(mat.define-palette(mat.$brown-palette, 600));
$admin: $primary;
$coordinator: $accent;
$projectLeader: mat.get-color-from-palette(mat.define-palette(mat.$lime-palette, 600));
$yellow: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette, 400));
$orange: mat.get-color-from-palette(mat.define-palette(mat.$orange-palette, 400));
$purple: mat.get-color-from-palette(mat.define-palette(mat.$purple-palette, 600));

