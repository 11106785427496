@use '@angular/material' as mat;
.data-summaries-and-durations-wrapper {
  display: flex;
  margin-left: auto;
  padding-left: 10px;
  flex-shrink: 2;
  flex-wrap: wrap;
  justify-content: flex-end;

  @include elBreakpoint($until: tp) {
    .data-summary-and-duration {
      padding: 2px 4px;
    }

    mat-icon {
      font-size: 12px;
      height: 12px;
      width: 12px;
      margin-right: 1px;
    }

    .label, .value {
      font-size: 11px;
    }
  }
  @include elBreakpoint($from: tp) {
    .data-summary-and-duration {
      padding: 3px 6px;
    }

    mat-icon {
      font-size: 15px;
      height: 15px;
      width: 15px;
      margin-right: 2px;
    }

    .label, .value {
      font-size: 14px;
    }
  }
}

.data-summary-and-duration {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 50px;
  line-height: 1;
  margin-top: 2px;
  margin-bottom: 2px;
  @include mat.elevation(1);

  .label {
    font-style: italic;
  }

  .value {
    font-weight: bold;
    margin-left: 4px;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }
}
