.chart-wrapper {
  &__header {
    display: flex;
    margin-bottom: 12px;
    line-height: 1;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      margin: 0 auto 0 0;
      flex-shrink: 1;
      line-height: inherit;
      @include mq($until: tp) {
        font-size: 14px;
      }
    }
  }
}

