@import "vars";
@import "mixins";

.full-screen-dialog,
.full-screen-dialog--wider-desktop,
full-screen-dialog--ultra-wide-desktop{

  @include mq($until: tp) {
    .mat-mdc-dialog-content {
      max-height: 100vh;
    }
  }

  @include mq($from: tp) {
    margin-top: 10px;

    .mat-mdc-dialog-content {
      max-height: calc(100vh - 150px);
    }
  }
}

.full-screen-dialog {
  @include mq($from: tp) {
    width: clamp(500px, calc(100vw - 40px), 880px);
  }
}

.full-screen-dialog--wider-desktop {
  @include mq($from: tp) {
    width: clamp(500px, calc(100vw - 40px), 1200px);
  }
}

.full-screen-dialog--ultra-wide-desktop {
  @include mq($from: tp) {
    width: auto;
    max-width: 2000px;
  }
}
