.mat-datepicker-dialog {
  min-width: 320px;

  .mat-mdc-dialog-container {
    padding: 0;
  }

  @media only screen and (max-height: 600px) {
    .mat-datepicker-content {
      position: relative;
    }
    .mat-calendar-header {
      padding-top: 0;
    }
    .mat-calendar-controls {
      margin-top: 0;
      margin-bottom: 0;
    }

    .mat-calendar-table-header th {
      padding-bottom: 4px;
    }

    .mat-datepicker-content .actions {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.datetime-picker-label .mat-mdc-simple-snack-bar {
  justify-content: center;
}
