@keyframes app-button-is-pending-spinner {
  to {
    transform: rotate(360deg);
  }
}

.mat-mdc-button-base.app-button-is-pending {
  mat-icon,
  .mdc-button__label {
    opacity: 0;
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: rgba(#000, 0);
    animation: app-button-is-pending-spinner .8s linear infinite;
  }

  &.mat-mdc-outlined-button,
  &.mat-mdc-icon-button {
    &:before {
      border-color: $gray-darker;
      border-top-color: rgba(#000, 0);
    }
  }
}

