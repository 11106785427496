@import "mixins";

mat-paginator {
  @include elBreakpoint($until: ml) {
    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 32px;
      height: 32px;
      padding: 5px;
    }

    .mat-mdc-paginator-page-size-select {
      width: 50px;

      .mdc-text-field--outlined {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }

  @include elBreakpoint($until: tp) {
    .mat-mdc-paginator-page-size-label {
      display: none;
    }
  }
  @include elBreakpoint($until: d) {
    .mat-mdc-paginator-range-label {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
