// Top and bottom spacing
ng-select.ng-select {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  font-size: $customFormFieldsFontSize;

  .ng-select-container {
    min-height: $customFormFieldsMinHeight;
  }
}

// Invalid field
ng-select.ng-invalid {
  div[role='combobox'] {
    background-color: rgba($notification, .4);
    opacity: 1 !important;
  }
}

// Multi select values styling
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background: none;
  border: 1px solid rgba($primary, .7);
  color: rgba($primary, .7);
  line-height: 1;
  padding: 0 4px 0 0;
  display: inline-flex;
  align-items: center;

  .ng-value-label {
    font-size: 11px;
    line-height: 11px;
    vertical-align: middle;
  }

  .ng-value-icon {
    color: rgba($primary, .7);
    font-size: 18px;
    padding-left: 5px;
    vertical-align: middle;

    &:hover {
      color: $accent;
    }
  }
}

// Panel width to be as wide as widest item
.ng-dropdown-panel--auto-grow .ng-dropdown-panel,
.ng-dropdown-panel.ng-dropdown-panel--auto-grow {
  width: auto !important;
}

// Panel min-width to be as wide as ng-select
.ng-dropdown-panel--min-width-full .ng-dropdown-panel,
.ng-dropdown-panel.ng-dropdown-panel--min-width-full {
  min-width: 100%;
}

// Smaller options
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 2.75em;
  min-height: 2.75em;
  padding: 0 12px;
  font-size: 13px;

  app-info-pane {
    white-space: normal;
    line-height: 1;
    padding-bottom: 4px;
    display: block;
  }

  .ng-select-option-bottom-text-line {
    line-height: 1;
    display: block;
    margin-top: -12px;
    font-style: italic;
    padding-bottom: 4px;
    color: $gray-darker;
  }
}


// disabled
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  color: $matInputFieldDisabledColor;
}


ng-select.ng-select.no-space-bottom {
  margin-bottom: 0;
}
