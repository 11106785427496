@import '../vendor/mq';
@import "vars";

// Hide elements
@mixin visually-hidden() {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.visually-hidden {
  @include visually-hidden();
}

@mixin elBreakpoint($from: false, $until: false) {
  // Only From
  @if $from and $until == false {
    &.data-elbp-from-#{$from} {
      @content;
    }
  }

    // Only Until
  @else if $until and $from == false {
    &.data-elbp-until-#{$until} {
      @content;
    }
  }

    // Both From and Until
  @else if $until and $from {
    &.data-elbp-until-#{$until}.data-elbp-from-#{$from} {
      @content;
    }
  }
}

// Dialogs with nested forms that do not have dialog-actions
@mixin matDialogFullHeightDueToNestedActions() {
  :host::ng-deep {
    .mat-mdc-dialog-content {
      @include mq($until: tp) {
        max-height: 100dvh;
      }
      @include mq($from: tp) {
        max-height: calc((100dvh - $appDialogTpAndUpTopMargin) - $space);
      }
    }
  }
}
