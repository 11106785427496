@import "vars";
@import "mixins";

.work-order-accordion {
  .mat-expansion-panel-header.mat-expanded {
    @include mq($until: tl) {
      height: 48px;
    }
  }

  .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  @include mq($until: tl) {
    .mat-expansion-panel {
      border-radius: 0 !important;
    }
  }

  .table-wrapper__filter-form-add-button-wrapper {
    padding-top: 0 !important;
  }
}
