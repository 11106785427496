@import "vars";
@import "mixins";

.mat-mdc-table {
  width: 100%;

  .mdc-data-table__row {
    height: 48px;
  }

  .mat-sort-header-content {
    font-size: 1em;
  }

  &.narrow-rows tr.mat-mdc-row, tr.mat-mdc-footer-row {
    height: 28px;
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    padding-right: 0px;

    &:last-child {
      width: 1%;
    }
  }

  td.mat-mdc-cell:last-child > .mdc-icon-button {
    margin-right: -$space;
  }

  // Cells
  @include elBreakpoint($until: d) {
    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
      font-size: .85em;
      line-height: 1.1;
    }

    th.mat-mdc-header-cell:first-child,
    td.mat-mdc-cell:first-child {
      padding-left: $space;
      padding-right: 3px;
    }

    th.mat-mdc-header-cell:not(:first-child):not(:last-child),
    td.mat-mdc-cell:not(:first-child):not(:last-child) {
      padding-left: $space * .33;
      padding-right: $space * .33;
    }

    th.mat-mdc-header-cell:last-child,
    td.mat-mdc-cell:last-child {
      padding-left: 3px;
      padding-right: $space;

      .menu-trigger,
      .mat-mdc-checkbox {
        margin-right: -$space;
      }
    }

    .mat-sort-header-arrow {
      margin: 0 0 0 3px;
    }
  }

  @include elBreakpoint($from: d) {
    th.mat-mdc-header-cell:first-child,
    td.mat-mdc-cell:first-child {
      padding-left: $space * 1.5;
      padding-right: 5px;
    }

    th.mat-mdc-header-cell:not(:first-child):not(:last-child),
    td.mat-mdc-cell:not(:first-child):not(:last-child) {
      padding-left: $space * .77;
      padding-right: $space * .77;
    }

    th.mat-mdc-header-cell:last-child,
    td.mat-mdc-cell:last-child {
      padding-left: 5px;
      padding-right: $space * 1.5;
      text-align: right;
      justify-content: right;
    }
  }

  // Buttons & Links
  th.mat-mdc-header-cell:last-child,
  td.mat-mdc-cell:last-child {
    button {
      opacity: .75;

      &:hover {
        opacity: 1;
      }
    }
  }

  .link {
    color: $gray;
  }

  // Sticky
  .mat-mdc-table-sticky:first-child {
    border-right: 3px solid #e0e0e0;
  }

  .mat-mdc-table-sticky:last-child:not(.never-as-last-cell) {
    border-left: 3px solid #e0e0e0;
  }

  app-company-type-icon {
    margin-right: 4px;
  }
}

.mat-mdc-header-cell.center {
  flex-direction: column;
  justify-content: center;

  .mat-sort-header-container {
    justify-content: center;
  }
}

.mat-mdc-cell.center {
  text-align: center;
  justify-content: center;
}

.mat-mdc-header-cell.right {
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;

  .mat-sort-header-container {
    justify-content: flex-end;
  }
}

.mat-mdc-cell.right {
  text-align: right;
  justify-content: flex-end;
}
