.sub-pages {
  @include mq($from: tl) {
    flex-grow: 1;
    flex-basis: 100%;
    padding-bottom: $space;
    overflow-x: auto;
  }
}

.sub-pages__outlet {
  background: white;
  border-top: 1px solid $gray-light;
  border-bottom: 1px solid $gray-light;

  &:not(.sub-pages__outlet--no-padding) {
    @include mq($until: tl) {
      padding: $space 0;
    }
    @include mq($from: tl) {
      padding: $space;
      border-left: 1px solid $gray-light;
      border-right: 1px solid $gray-light;
    }
  }

  &.sub-pages__outlet--no-padding {
    @include mq($until: tl) {
      padding-top: $space;
    }
    @include mq($from: tl) {
      padding-top: $space;
      border-left: 1px solid $gray-light;
      border-right: 1px solid $gray-light;
    }
  }
}
