.info-line {
  display: flex;
  margin-bottom: 4px;
  padding-bottom: 4px;
  gap: 2px 6px;
  justify-content: space-between;
  border-bottom: 1px dashed gray;
  align-items: center;
  line-height: 1.05;
  min-height: 20px;

  .label {
    font-style: italic;
  }

  .value {
    margin-left: auto;
    text-align: right;
  }

  &.info-line__multi-line {
    flex-wrap: wrap;

    .label {
      width: 100%;

      .mat-icon.mat-icon-inline {
        width: auto;
      }
    }

    .value {
      text-align: left;
      margin-left: initial;
    }
  }
}
