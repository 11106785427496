@import "mixins";

mat-vertical-stepper {
  @include mq($until: ml) {
    .mat-step:not(:first-child) {
      border-top: 1px dashed $gray-dark;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
      padding: 16px 12px;
    }

    .mat-vertical-content-container {
      margin-left: 0;

      &:before {
        display: none;
      }
    }

    .mat-vertical-content {
      padding: 0 12px 24px 12px;
    }
  }
}
