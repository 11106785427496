@use '@angular/material' as mat;

.thumbnail {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  background-position: center center;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  border-radius: 4px;
  @include mat.elevation(1);
}
