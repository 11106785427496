@use '@angular/material' as mat;
@use "sass:map";

// Container
.container {
  padding: $space;
  max-width: 100vw;
  box-sizing: border-box;
}

// Row Of Buttons
.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;

  .mdc-icon-button {
    flex: 0 0;
  }

  .mdc-button {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .mat-icon {
    flex-shrink: 0;
  }
}

// Display
.display-none {
  display: none !important;
}

.mq-display-none-from-ml {
  @include mq($from: ml) {
    display: none !important;
  }
}

.mq-display-none-until-ml {
  @include mq($until: ml) {
    display: none !important;
  }
}

.mq-display-none-from-tp {
  @include mq($from: tp) {
    display: none !important;
  }
}

.mq-display-none-until-tp {
  @include mq($until: tp) {
    display: none !important;
  }
}

// 1 property definitions (sorta)
.no-wrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.list-reset {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.full-width {
  width: 100%;
}

// Colors
$baseColors: (
  // MAT
  'primary': $primary,
  'accent': $accent,
  'warn': $warn,
  // Experi
  'calm': $calm,
  'notification': $notification,
  'positive': $positive,
  // User roles
  'superAdmin': $superAdmin,
  'admin': $primary,
  'coordinator': $accent,
  'mechanic': $calm,
  'external': $positive,
  'sensorObserver': $notification,
  'externalCoordinator': $gray-darker,
  'externalEventPlanner': $warn,
  'projectLeader': $projectLeader,
  // BreakDownMechanicCalendars
  'break-down-service': #d3afd9, // Mirrors const breakDownServiceColor.break-down-service @line 8.:app-config.ts
  'national-holiday': #fea92e,
  'weekly-non-working': #000000,
  'working-day-without-calendar-items': lighten($positive, 29%),
);

$colorColors: (
  'white': $white,
  'gray': $gray,
  'gray-dark': $gray-dark,
  'gray-darker': $gray-darker,
  'gray-darkest': $gray-darkest,
  'gray-light': $gray-light,
  'gray-lighter': $gray-lighter,
  'orange': $orange,
  'purple': $purple,
);

@each $name, $color in map.merge($baseColors, $colorColors) {
  .color--#{$name} {
    color: $color !important;
  }
}

@each $name, $color in map.merge($baseColors, $colorColors) {
  .background--#{$name} {
    background-color: $color !important;
  }
}

$borderColorColors: ();
@each $name, $color in map.merge($baseColors, $borderColorColors) {
  .border-color--#{$name} {
    border-color: $color !important;
  }
}
