.cal-week-view {
  .cal-starts-within-day .cal-event {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.cal-event {
  user-select: none;
}

.cal-event-title {
  white-space: normal;
  line-height: 12px;
  display: inline-block;
}
