.default-detail-page-wrapper {
  margin: 0 auto;

  .default-table-wrapper .table-wrapper__filter-form-add-button-wrapper {
    padding-top: 0;
  }

  .mat-mdc-tab-nav-bar, .mat-mdc-tab-header {
    border-bottom: none;
  }

  @include mq($from: d) {
    display: flex;
    padding: $space;
    max-width: 2000px;
    box-sizing: border-box;

    .default-table-wrapper .table-wrapper__filter-form-add-button-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.default-detail-page-wrapper__info {
  @include mq($from: d) {
    flex-shrink: 0;
    padding-top: $space;
    padding-bottom: $space;
    width: 350px;
  }

  @include mq($from: d) {
    .data-summary-and-duration {
      margin-right: 0 !important;
      justify-self: flex-end;
    }
  }

  h1 {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    line-height: 1;
  }

  h2 {
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: $space;
  }

  h3 {
    line-height: 1.2;
    margin-bottom: 2px;

    a {
      color: $accent;
    }
  }
}

.default-detail-page-wrapper__edit-btn {
  padding: 12px 0;
}

.default-detail-page-wrapper__breadcrumbs-dates {
  @include mq($until: d) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    app-breadcrumbs {
      margin: 4px 10px 4px 0;
      flex-shrink: 0;
    }
    app-location-detail-dates-form {
      max-width: 220px;
      margin-left: auto;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

.default-detail-page-wrapper__dates-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .mdc-icon-button {
    padding: 4px;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 40px;
  }

  mat-form-field {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
